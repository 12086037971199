<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <el-card class="el-main station-add">
      <el-form ref="projectForm" :model="request" :rules="rules">
        <div class="a-fs-16 a-c-333 a-fw-b">
          <span>站点信息</span>
        </div>
        <div class="a-flex-cfsfs a-mt-10">
          <el-form-item prop="companyId">
            <le-company-under-select
              v-model="request.companyId"
              label="所属商户"
              placeholder="请选择（可搜索）"
              notnull
              large
              @input="handleCompanyIdChange"
            />
          </el-form-item>
          <el-form-item prop="name">
            <le-input v-model="request.name" label="站点名称" maxlength="40" notnull large />
          </el-form-item>
          <le-input-address-point
            label="详细地址"
            :province.sync="request.provinceCode"
            :city.sync="request.cityCode"
            :area.sync="request.districtCode"
            :address.sync="request.address"
            :lat.sync="request.lat"
            :lng.sync="request.lng"
            large
          ></le-input-address-point>
        </div>
        <div class="a-fs-16 a-c-333 a-fw-b">
          <span>项目信息</span>
        </div>
        <div class="a-flex-cfsfs a-mt-10">
          <el-form-item prop="incomeDistriId">
            <le-input-slot label="项目收益" notnull>
              <template v-if="!request.incomeDistriId">
                <el-button
                  class="s-btn-add-blue-dot"
                  icon="el-icon-plus"
                  @click="handlerChooseIncome"
                >
                  选择
                </el-button>
              </template>
              <template v-else>
                <div class="a-flex-rfsfs s-large-input">
                  <el-input v-model="request.incomeDistriName" readonly></el-input>
                  <el-button
                    class="s-btn-add-blue-dot a-ml-15"
                    icon="el-icon-refresh-right"
                    @click="incomeVisiable = true"
                  >
                    重选
                  </el-button>
                </div>
              </template>
            </le-input-slot>
          </el-form-item>
        </div>
        <div class="a-fs-16 a-c-333 a-fw-b">
          <span>定价模板</span>
        </div>
        <div class="a-flex-cfsfs a-mt-10">
          <el-form-item prop="planId">
            <le-input-slot label="站点定价" notnull>
              <template v-if="!request.planId">
                <el-button
                  class="s-btn-add-blue-dot"
                  icon="el-icon-plus"
                  @click="handleOpenPriceVisible"
                >
                  选择
                </el-button>
              </template>
              <template v-else>
                <div class="a-flex-rfsfs s-large-input">
                  <el-input v-model="request.planName" readonly></el-input>
                  <el-button
                    class="s-btn-add-blue-dot a-ml-15"
                    icon="el-icon-refresh-right"
                    @click="handleOpenPriceVisible"
                  >
                    重选
                  </el-button>
                </div>
              </template>
            </le-input-slot>
          </el-form-item>
        </div>
        <div class="a-line-t-e0 footerBox">
          <el-button type="primary" class="a-ml-24 a-mt-15" @click="handlerSumbit">提交</el-button>
          <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="handlerCancel">
            取消
          </el-button>
        </div>
      </el-form>
    </el-card>
    <le-address-choose
      :visible="true"
      :price-id.sync="request.planId"
      :price-name.sync="request.planName"
    ></le-address-choose>
    <le-project-choose
      :visible.sync="incomeVisiable"
      :income-id.sync="request.incomeDistriId"
      :income-name.sync="request.incomeDistriName"
      :company-id="request.companyId"
      :status="4"
    ></le-project-choose>
    <le-price-choose
      :visible.sync="priceVisiable"
      :price-id.sync="request.planId"
      :price-name.sync="request.planName"
      :company-id="request.companyId"
    ></le-price-choose>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "StationAdd",

  data() {
    return {
      request: {
        name: "",
        companyId: null,
        provinceCode: "",
        cityCode: "",
        districtCode: "",
        address: "",
        lng: 0,
        lat: 0,
        incomeDistriId: "",
        incomeDistriName: "",
        planId: "",
        planName: "",
      },
      incomeVisiable: false,
      priceVisiable: false,
      rules: {
        companyId: [
          {
            required: true,
            message: "请选择站点所属商户",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入站点名称",
            trigger: "blur",
          },
        ],
        incomeDistriId: [
          {
            required: true,
            message: "请选择项目收益",
            trigger: "blur",
          },
        ],
        planId: [
          {
            required: true,
            message: "请选择站点定价",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },

  watch: {
    "request.companyId": {
      handler(val, oldVal) {
        if (val != oldVal) {
          this.request.incomeDistriId = null;
        }
        if (!val) {
          this.request.planId = "";
        }
      },
    },

    "request.incomeDistriId"(val) {
      if (val) {
        this.$refs.projectForm.clearValidate(["incomeDistriId"]);
      }
    },

    "request.planId"(val) {
      if (val) {
        this.$refs.projectForm.clearValidate(["planId"]);
      }
    },
  },

  mounted() {
    this.request.companyId = this.company.id;
  },

  methods: {
    handlerSumbit() {
      this.$refs.projectForm.validate((valid) => {
        if (valid) {
          if (this.stationId) {
            this.$Axios
              ._post({
                url: this.$Config.apiUrl.editStation,
                params: {
                  ...this.request,
                  id: this.stationId,
                },
              })
              .then(() => {
                this.$message("修改站点成功");
                this.$router.push("/station/station-list");
                this.$store.dispatch("delView", this.$route);
              });
          } else {
            this.$Axios
              ._post({
                url: this.$Config.apiUrl.createStation,
                params: this.request,
              })
              .then(() => {
                this.$message("创建站点成功");
                this.$router.push("/station/station-list");
                this.$store.dispatch("delView", this.$route);
              });
          }
        }
      });
    },

    handlerCancel() {
      this.$router.push("/station/station-list");
      this.$store.dispatch("delView", this.$route);
    },

    handlerChooseIncome() {
      if (this.request.companyId) {
        this.incomeVisiable = true;
      } else {
        this.$message.warning("请先选择所属商户");
      }
    },

    handleOpenPriceVisible() {
      if (!this.request.companyId) {
        this.$message.warning("请先选择所属商户");
        return;
      }
      this.priceVisiable = true;
    },

    handleCompanyIdChange(val) {
      if (val && this.request.planId) {
        this.request.planId = "";
      }
    },
  },
};
</script>

<style lang="scss">
.station-add {
  .el-form-item__error {
    left: 100px;
  }

  .footerBox {
    left: 255px;
    right: 43px;
    position: fixed;
    bottom: 0;
    background: #fff;
    padding-bottom: 24px;
  }
}
</style>
